import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { GeneralStatePrototype } from './general-state.model';
import { SetSubmittingStatus } from './general.actions';

@State<GeneralStatePrototype>({
  name: 'general',
})
@Injectable()
export class GeneralState {
  @Action(SetSubmittingStatus)
  public setSubmitting(
    ctx: StateContext<GeneralStatePrototype>,
    { key, status }: SetSubmittingStatus
  ): void {
    const state = ctx.getState();
    const submitting = state?.submitting ?? {};

    submitting[key] = status;

    ctx.patchState({
      submitting,
    });
  }
}
