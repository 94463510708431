import { FieldArrayType } from '@ngx-formly/core';
import { Component, EventEmitter, OnDestroy } from '@angular/core';

@Component({
  template: ``,
  styleUrls: ['./info-table.formly-type.scss'],
})
export class InfoTableFormlyTypeComponent
  extends FieldArrayType
  implements OnDestroy
{
  public destroy$: EventEmitter<void> = new EventEmitter<void>();

  public ngOnDestroy(): void {
    this.destroy$.next(null);
  }
}
