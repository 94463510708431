import { Pipe, PipeTransform } from '@angular/core';
import { Entity } from '@core/enums/entity.enum';
import { EntityAction } from '@core/enums/entity-action.enum';
import { Observable, of, zip } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'getAction',
})
export class GetActionPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  public transform(action: EntityAction, entity?: Entity): Observable<string> {
    let actionKey = '';

    switch (action) {
      case EntityAction.Create:
        actionKey = `button.new`;
        break;
      case EntityAction.Add:
        actionKey = `button.add`;
        break;
      case EntityAction.Update:
        actionKey = `button.edit`;
        break;
      case EntityAction.Delete:
        actionKey = `button.delete`;
        break;
      default:
    }

    return zip(this.translateService.stream(actionKey), of(entity)).pipe(
      map(([actionTranslation, entityTranslation]) => {
        return `${actionTranslation} ${entityTranslation}`;
      })
    );
  }
}
