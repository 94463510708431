import {
  ActionOptions,
  ActionProvider,
  NavigateAction,
} from '@capturum/builders/core';
import { Router } from '@angular/router';
import { ToastService } from '@capturum/ui/api';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class ToastNavigateAction implements ActionProvider {
  constructor(
    private router: Router,
    private toastService: ToastService,
    private translateService: TranslateService,
    private navigateAction: NavigateAction
  ) {}

  public execute(
    options: ActionOptions,
    item: any,
    context?: Record<string, any>
  ): any {
    this.toastService.success(
      this.translateService.instant('toast.success.title'),
      this.translateService.instant('isp.toast.success.message')
    );

    this.navigateAction.execute(options, item, context);
  }
}
