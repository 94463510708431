import { TranslateService } from '@ngx-translate/core';
import { InfoTableColumn, InfoTableColumnType } from '@capturum/ui/info-table';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ArticleProcessing } from '@core/models/order.model';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TableField } from '@core/enums/table-field.enum';
import { FieldType } from '@ngx-formly/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-article-details',
  templateUrl: './article-details.component.html',
  styleUrls: ['./article-details.component.scss'],
})
export class ArticleDetailsComponent
  extends FieldType
  implements OnInit, OnDestroy
{
  @Input() public tableData: ArticleProcessing[];
  public tableColumns: InfoTableColumn[];
  public TableField: typeof TableField = TableField;
  public remarks: { remark: string; order_article: boolean }[] = [];
  public isReadonly: boolean;
  private destroy$ = new Subject<boolean>();

  constructor(
    private translateService: TranslateService,
    private dialogConfig: DynamicDialogConfig
  ) {
    super();
  }

  public ngOnInit(): void {
    this.prepareColumns();
    this.prepareTableData();

    this.isReadonly = this.dialogConfig.data.isReadonly;
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public setRemarks(): void {
    this.field.form.controls.infoTable.setValue(this.remarks);
  }

  private prepareColumns(): void {
    this.tableColumns = [
      {
        field: TableField.OrderArticle,
        title: '',
        disableSort: true,
        sortable: {
          enabled: false,
          direction: 'desc',
        },
        type: InfoTableColumnType.Template,
      },
      {
        field: TableField.ArticleName,
        title: this.translateService.stream(
          'isp.order.field.subcategory_name.title'
        ),
        sortable: {
          enabled: false,
          direction: 'desc',
        },
      },
      {
        field: TableField.Price,
        title: this.translateService.stream('isp.order.field.price.title'),
        sortable: {
          enabled: false,
          direction: 'desc',
        },
      },
      {
        field: TableField.Remark,
        title: this.translateService.stream('isp.order.field.comment.title'),
        type: InfoTableColumnType.Template,
        sortable: {
          enabled: true,
          direction: 'desc',
        },
      },
    ];
  }

  private prepareTableData(): void {
    if (this.dialogConfig?.data?.processing) {
      this.tableData = this.dialogConfig.data.processing;
      this.setRemarkData();
    } else if (this.to?.updateSelectedOptions) {
      this.to.updateSelectedOptions
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          this.tableData = response?.length
            ? response.map((item, index) => {
                return {
                  [TableField.ArticleId]: item.value,
                  [TableField.ArticleName]: item.label,
                  [TableField.Remark]: this.remarks[index]?.remark,
                  [TableField.OrderArticle]: item.order_article,
                  [TableField.Price]: item.price,
                };
              })
            : [];

          this.setRemarkData();
          this.setRemarks();
        });
    }
  }

  private setRemarkData(): void {
    this.remarks =
      this.tableData?.map((row) => {
        return {
          remark: row.remark,
          order_article: row.order_article,
          price: row.price,
        };
      }) || [];
  }
}
