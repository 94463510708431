import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicLayoutComponent } from '@shared/modules/layout/layouts/basic-layout/basic-layout.component';
import { HeaderComponent } from '@shared/modules/layout/components/header/header.component';
import { RouterModule } from '@angular/router';
import { CompleteModule } from '@capturum/complete';
import { SharedModule } from '@shared/shared.module';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CapturumUserMenuModule } from '@capturum/ui/user-menu';

const BASE_LAYOUTS = [BasicLayoutComponent];

@NgModule({
  declarations: [HeaderComponent, ...BASE_LAYOUTS],
  exports: [...BASE_LAYOUTS],
  imports: [
    CommonModule,
    RouterModule,
    CompleteModule,
    SharedModule,
    OverlayPanelModule,
    CapturumUserMenuModule,
  ],
})
export class LayoutModule {}
