import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ApiModule } from '@capturum/api';
import { AuthModule } from '@capturum/auth';
import {
  CapturumHttpCodeInterceptor,
  CompleteModule,
  ErrorMessageInterceptor,
} from '@capturum/complete';
import { LoginModule } from '@capturum/login';
import { SharedModule } from '@capturum/shared';
import { LoginService } from '@core/services/login.service';
import { environment } from '@environments/environment';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';

function onAuthError(): void {
  window.location.href = `auth/login`;
}

@NgModule({
  declarations: [],
  imports: [
    NgxPermissionsModule.forRoot(),
    AuthModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      authRejectionRoute: 'auth/login',
    }),
    ApiModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      onAuthError,
    }),
    LoginModule.forRoot({
      environment,
      productName: 'Internet Sales Platform',
      redirectAfterLogin: 'admin/order/add',
      loginService: LoginService,
      displayPassword: true,
      defaultLanguage: 'en',
      logoImageUrl: '/assets/images/logo-verbiest.jpg',
    }),
    SharedModule.forRoot(),
    CompleteModule.forRoot(
      {
        indexedDbModels: [],
        databaseName: environment.databaseName,
        version: 1,
        environment,
      },
      NgxPermissionsService,
      NgxPermissionsModule
    ),
  ],
  providers: [
    CapturumHttpCodeInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CapturumHttpCodeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorMessageInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
