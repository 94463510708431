import { Injectable } from '@angular/core';
import {
  LoginConfig,
  LoginService as CapturumLoginService,
} from '@capturum/login';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@capturum/ui/api';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends CapturumLoginService {
  constructor(
    protected readonly config: LoginConfig,
    protected readonly router: Router,
    protected readonly toastService: ToastService,
    protected readonly translateService: TranslateService,
    protected readonly formBuilder: UntypedFormBuilder,
    protected readonly route: ActivatedRoute
  ) {
    super(config, router, route, toastService, translateService, formBuilder);
  }

  public onLoginFail(result: any): void {
    switch (result.status) {
      case 422:
        this.toastService.error(
          this.translateService.instant('link.login'),
          this.translateService.instant('auth.sign-in.error')
        );
        break;
      default:
        if (result.error && result.error.message) {
          this.toastService.error(
            this.translateService.instant('toast.error.title'),
            result.error.message
          );
        }
        break;
    }
  }

  public onForgotFail(result: any): void {
    this.toastService.error(
      this.translateService.instant('auth.forgot-password.link'),
      this.translateService.instant('auth.forgot-password.error')
    );
  }
}
