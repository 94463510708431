<div class="table-filters cap-table-filter">
  <div class="d-flex table-filters">
    <div class="filters-label">
      {{ "table.filters" | translate }}
    </div>

    <div class="filters w-100">
      <div class="table-filter-row row" *ngIf="activeFilters">
        <div
          class="align-vertical filter col-12 col-md-4 mb-2"
          [ngClass]="filter.wrapperStyleClass"
          *ngFor="let filter of filters"
        >
          <ng-container [ngSwitch]="filter.type">
            <cap-input
              *ngSwitchCase="filterType.INPUT"
              class="has-icon"
              styleClass="cap-rounded"
              [ngModel]="
                filter.isSearch
                  ? activeFilters?.global?.value
                  : activeFilters[filter.field]?.value
              "
              [placeholder]="filter.placeholder | observablePipe | async"
              (onInput)="
                setFilter(
                  $event.target.value,
                  filter.field,
                  filter.matchMode || 'like',
                  filter.isSearch
                )
              "
              #input
            >
            </cap-input>

            <ng-container *ngSwitchCase="filterType.MULTISELECT">
              <ng-container
                *ngIf="filter.options | observablePipe | async as options"
              >
                <cap-multi-select
                  class="has-icon"
                  [options]="options"
                  *ngSwitchCase="filterType.MULTISELECT"
                  styleClass="cap-rounded"
                  [defaultLabel]="filter.placeholder | observablePipe | async"
                  (change)="setFilter($event.value, filter.field, 'in')"
                  [ngModel]="activeFilters[filter.field]?.value"
                >
                </cap-multi-select>
              </ng-container>
            </ng-container>

            <cap-calendar
              *ngSwitchCase="filterType.DATEPICKER"
              styleClass="cap-rounded w-100"
              [readonlyInput]="true"
              [ngModel]="activeFilters[filter.field]?.value"
              (select)="
                setFilter($event, filter.field, filter.matchMode || 'equals')
              "
              [placeholder]="filter.placeholder"
            >
            </cap-calendar>

            <div
              class="int-checkbox-filter-container"
              *ngSwitchCase="filterType.INPUT_SWITCH"
            >
              <div class="int-checkbox-filter">
                <div class="int-checkbox-filter-label">
                  {{ filter.label }}
                </div>

                <cap-checkbox
                  [binary]="true"
                  [ngModel]="activeFilters[filter.field]?.value.length === 2"
                  (change)="setInputSwitchFilter($event.checked, filter.field)"
                ></cap-checkbox>
              </div>
            </div>
          </ng-container>

          <ng-container
            *ngTemplateOutlet="filterIcon; context: { icon: filter.icon }"
          >
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #filterIcon let-icon="icon">
  <div class="filter-icon">
    <i [ngClass]="icon"></i>
  </div>
</ng-template>
