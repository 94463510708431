export enum TableField {
  ArticleId = 'ref_article_id',
  ArticleName = 'ref_article_name',
  Unit = 'ref_unit',
  UnitName = 'ref_unit_name',
  Quantity = 'quantity',
  Selected = 'selected',
  ClientNumber = 'ref_client_nr',
  Name = 'name',
  ClientName = 'client_name',
  ClientShipTo = 'ref_client_ship_to',
  Address = 'address',
  City = 'city',
  Country = 'country',
  Category = 'ref_category_id',
  CategoryName = 'ref_category_name',
  Subcategory = 'ref_subcategory_id',
  SubcategoryName = 'ref_subcategory_name',
  Details = 'details',
  Remark = 'remark',
  OrderArticle = 'order_article',
  Price = 'price',
}
