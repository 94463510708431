import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
  NgModule,
} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { PermissionGuard, TranslationLoader } from '@capturum/complete';
import { CoreModule } from '@core/core.module';
import { LayoutModule } from '@shared/modules/layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppInjector } from './app-injector.service';
import { CapturumToastModule } from '@capturum/ui/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumMissingTranslationHandler } from '@capturum/shared';
import { environment } from '@environments/environment';
import { GeneralState } from './store/general/general.state';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { ListDeleteService } from '@core/actions/list-delete.service';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { CapturumBuilderCoreModule } from '@capturum/builders/core';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { EditorComponent } from '@shared/formly/types/editor/editor.component';
import { CapturumEditorModule } from '@capturum/ui/editor';
import { ToastNavigateAction } from '@core/actions/toast-navigate.service';
import { SentryErrorHandler } from '@core/error-handlers/SentryErrorHandler';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';

Sentry.init({
  dsn: environment.sentryUrl,
  environment: environment.name,
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CapturumBuilderCoreModule.forRoot({
      actions: {
        delete: ListDeleteService,
        toastNavigate: ToastNavigateAction,
      },
    }),
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CapturumMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
      },
    }),
    CoreModule,
    LayoutModule,
    CapturumToastModule,
    NgxsModule.forRoot([GeneralState]),
    CapturumEditorModule,
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    CapturumListRendererModule.forRoot(),
    CapturumFormRendererModule.forRoot({
      translationKeyPrefix: 'isp',
      types: [
        {
          name: 'editor',
          component: EditorComponent,
        },
      ],
    }),
    ConfirmDialogModule,
    CapturumButtonModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    PermissionGuard,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        return () => {};
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);
  }
}
