enum EntityRoutes {
  admin = 'admin',
  user = 'users',
  role = 'role',
  translation = 'translation',
  baseData = 'base-data',
  setting = 'setting',
  order = 'order',
  promoOfTheWeek = 'promo/show',
  popup = 'popup',
  promo = 'promo',
}

enum HelperRoutes {
  profile = 'profile',
  config = 'config',
}

enum GeneralRoutes {
  detail = 'detail',
  view = 'view',
  add = 'add',
  edit = 'edit',
}

enum AuthRoutes {
  auth = 'auth',
  login = 'login',
}

export type AppRoutes = typeof AppRoutes;
export const AppRoutes = {
  ...EntityRoutes,
  ...GeneralRoutes,
  ...HelperRoutes,
  ...AuthRoutes,
};
