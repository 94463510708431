<ng-container *ngxPermissionsOnly="permissions">
  <div
    *ngIf="actionItems.length"
    class="primary menu-action square {{ styleClass }}"
    (capClickOutside)="isOpen = false"
  >
    <i class="fas fa-ellipsis-v" (click)="toggleMenu()"></i>

    <ul class="menu-action__items" [class.menu-action__items--open]="isOpen">
      <ng-container *ngFor="let action of actionItems">
        <ng-container *ngxPermissionsOnly="action.permissions">
          <li
            [attr.data-test]="action?.label"
            [class.disabled]="action.disabled || false"
            (click)="executeAction(action)"
          >
            <div class="menu-action__icon">
              <i [class]="action?.icon"></i>
            </div>

            <div class="menu-action__info">
              <p class="title">{{ action?.label | translate }}</p>
            </div>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </div>
</ng-container>
