<router-outlet></router-outlet>
<cap-toast position="top-right"></cap-toast>

<ng-container *ngIf="isAuthenticated$ | async">
  <p-confirmDialog #cd appendTo="body">
    <p-footer>
      <cap-button
        styleClass="secondary"
        type="button"
        [label]="confirmation?.rejectLabel || ('confirm.no' | translate)"
        (click)="cd.reject()"
      >
      </cap-button>

      <cap-button
        data-test="dialog-confirm-button"
        type="button"
        [label]="confirmation?.acceptLabel || ('confirm.yes' | translate)"
        [styleClass]="confirmation?.acceptButtonStyleClass || 'primary'"
        (click)="cd.accept()"
      >
      </cap-button>
    </p-footer>
  </p-confirmDialog>
</ng-container>
