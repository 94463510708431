import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { loginRoutes } from '@capturum/login';
import {
  BaseDataResolver,
  IndexedDbGuard,
  PermissionGuard as BlueprintPermissionGuard,
  PublicTranslationResolver,
  SettingResolver,
  TranslationResolver,
} from '@capturum/complete';
import { AuthGuard } from '@capturum/auth';
import { BasicLayoutComponent } from '@shared/modules/layout/layouts/basic-layout/basic-layout.component';
import { PermissionGuard } from '@core/guards/permission.guard';
import { NgxPermissionsGuard } from 'ngx-permissions';

const routes: Routes = [
  {
    ...loginRoutes[0],
    canActivate: [IndexedDbGuard],
    resolve: {
      publicTranslations: PublicTranslationResolver,
    },
    children: [...loginRoutes[0].children],
  },
  {
    path: '',
    canActivate: [IndexedDbGuard, AuthGuard, BlueprintPermissionGuard],
    resolve: {
      translations: TranslationResolver,
      baseData: BaseDataResolver,
      settings: SettingResolver,
    },
    children: [
      {
        path: '',
        redirectTo: 'admin',
        pathMatch: 'full',
      },
      {
        path: 'admin',
        component: BasicLayoutComponent,
        children: [
          {
            path: '',
            redirectTo: 'order',
            pathMatch: 'full',
          },
          {
            path: 'order',
            loadChildren: () => {
              return import('./features/order/order.module').then((m) => {
                return m.OrderModule;
              });
            },
          },
          {
            path: 'roles',
            loadChildren: () => {
              return import('./features/role/role.module').then((m) => {
                return m.RoleModule;
              });
            },
            data: {
              permissions: {
                only: 'role.manage.tenant',
                redirectTo: '/',
              },
            },
          },
          {
            path: 'translations',
            loadChildren: () => {
              return import('./features/translation/translation.module').then(
                (m) => {
                  return m.TranslationModule;
                }
              );
            },
          },
          {
            path: 'users',
            loadChildren: () => {
              return import('./features/user/user.module').then((m) => {
                return m.UserModule;
              });
            },
          },
          {
            path: 'settings',
            loadChildren: () => {
              return import('./features/settings/settings.module').then((m) => {
                return m.SettingsModule;
              });
            },
          },
          {
            path: 'promo',
            canActivate: [NgxPermissionsGuard],
            loadChildren: () => {
              return import('./features/promo/promo.module').then((m) => {
                return m.PromoModule;
              });
            },
            data: {
              permissions: {
                only: ['week_promo.manage', 'week_promo.show'],
                redirectTo: '/',
              },
            },
          },
          {
            path: 'base-data',
            loadChildren: () => {
              return import('./features/base-data/base-data.module').then(
                (m) => {
                  return m.BaseDataModule;
                }
              );
            },
            canActivate: [PermissionGuard],
            data: {
              permissions: {
                only: 'base-data-value.manage.tenant',
                redirectTo: '/',
              },
            },
          },
          {
            path: 'builder',
            loadChildren: () => {
              return import('@capturum/builders/pages').then((m) => {
                return m.CapturumBuilderPagesModule;
              });
            },
          },
          {
            path: 'popup',
            canActivate: [NgxPermissionsGuard],
            loadChildren: () => {
              return import('./features/popup/popup.module').then((m) => {
                return m.PopupModule;
              });
            },
            data: {
              permissions: {
                only: 'week_promo.manage',
                redirectTo: '/',
              },
            },
          },
          {
            path: 'config',
            loadChildren: () => {
              return import('./features/config/config.module').then((m) => {
                return m.ConfigModule;
              });
            },
            canActivate: [PermissionGuard],
            data: {
              permissions: {
                only: [
                  'base-data.value.manage.tenant',
                  'user.manage.tenant',
                  'user.manage',
                  'translation.manage.tenant',
                  'translation.manage',
                ],
                redirectTo: '/',
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'order',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
