import { Component, Input } from '@angular/core';
import { EntityAction } from '@core/enums/entity-action.enum';
import { Entity } from '@core/enums/entity.enum';

@Component({
  selector: 'app-page-layout-header',
  templateUrl: './page-layout-header.component.html',
  styleUrls: ['./page-layout-header.component.scss'],
})
export class PageLayoutHeaderComponent {
  @Input() public backUrl: string[];
  @Input() public action: EntityAction;
  @Input() public entity: Entity;
}
