import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Popup } from '../../../features/popup/models/popup.model';

@Component({
  selector: 'app-popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.scss'],
})
export class PopupDialogComponent implements OnInit {
  public popup: Popup;

  constructor(private dialogConfig: DynamicDialogConfig) {}

  public ngOnInit(): void {
    this.popup = this.dialogConfig.data?.popup;
  }
}
