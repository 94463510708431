import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormModule as CapturumFormlyModule } from '@capturum/formly';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumInputModule } from '@capturum/ui/input';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { ActionMenuComponent } from '@shared/components/action-menu/action-menu.component';
import { PageLayoutHeaderComponent } from '@shared/components/page-layout-header/page-layout-header.component';
import { ReadonlyPropertyComponent } from '@shared/components/readonly-property/readonly-property.component';
import { ButtonLoadingDirective } from '@shared/directives/button-loading.directive';
import { GetActionPipe } from '@shared/pipes/get-action.pipe';
import { GetBackPipe } from '@shared/pipes/get-back.pipe';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogContainerComponent } from './components/dynamic-dialog-container/dynamic-dialog-container.component';
import { TableFiltersComponent } from './components/table-filters/table-filters/table-filters.component';
import { InputNumberFormlyTypeComponent } from '@shared/formly/types/input-number/input-number.formly-type';
import { InputNumberModule } from 'primeng/inputnumber';
import { InfoTableFormlyTypeComponent } from './formly/types/info-table/info-table.formly-type';
import { ArticleDetailsComponent } from '../features/order/components/article-details/article-details.component';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorComponent } from './formly/types/editor/editor.component';
import { CapturumEditorModule } from '@capturum/ui/editor';
import { PopupDialogComponent } from '@shared/components/popup-dialog/popup-dialog.component';

const BASE_PIPES = [GetActionPipe, GetBackPipe];

const BASE_COMPONENTS = [
  ActionMenuComponent,
  PageLayoutHeaderComponent,
  ReadonlyPropertyComponent,
  TableFiltersComponent,
  DynamicDialogContainerComponent,
  InputNumberFormlyTypeComponent,
  InfoTableFormlyTypeComponent,
  PopupDialogComponent,
];

const BASE_DIRECTIVES = [ButtonLoadingDirective];

@NgModule({
  declarations: [
    ...BASE_PIPES,
    ...BASE_COMPONENTS,
    ...BASE_DIRECTIVES,
    EditorComponent,
  ],
  imports: [
    CommonModule,
    NgxPermissionsModule,
    CapturumButtonModule,
    TranslateModule,
    CapturumSharedModule,
    RouterModule,
    CdkStepperModule,
    CapturumInputModule,
    DialogModule,
    FormlyModule.forRoot({
      types: [
        {
          name: 'inputNumber',
          component: InputNumberFormlyTypeComponent,
          wrappers: ['cap-form-field'],
        },
        {
          name: 'infoTable',
          component: ArticleDetailsComponent,
          wrappers: ['cap-form-field'],
        },
      ],
    }),
    CapturumFormlyModule,
    InputNumberModule,
    CapturumMultiSelectModule,
    CapturumCalendarModule,
    CapturumCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    CapturumEditorModule,
  ],
  exports: [
    ...BASE_PIPES,
    ...BASE_COMPONENTS,
    ...BASE_DIRECTIVES,
    NgxPermissionsModule,
    TranslateModule,
    CapturumSharedModule,
    CdkStepperModule,
    FormlyModule,
    CapturumFormlyModule,
  ],
})
export class SharedModule {}
