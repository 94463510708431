<div class="g-property">
  <div class="g-property__label">
    {{ label }}
  </div>

  <div class="g-property__value">
    <div #valueReference>
      <ng-content></ng-content>
    </div>

    <div *ngIf="!valueReference?.children?.length">
      {{ value }}
    </div>

    <ng-container *ngIf="!valueReference?.children?.length && !value">
      <cap-skeleton height="14px" width="100px"></cap-skeleton>
    </ng-container>
  </div>
</div>
