<nav class="navbar navbar-static-top white-bg header d-flex" role="navigation">
  <div class="container position-relative">
    <div class="row align-items-center justify-content-center">
      <div class="col-md-2 logo d-flex justify-content-start">
        <a routerLink="/">
          <img alt="logo" [src]="'logo-verbiest.jpg' | assets : 'images'" />
        </a>
      </div>

      <div class="col-6 col-md-4 notification-message">
        {{ "isp.ordering_warning_message" | translate }}
      </div>

      <div class="col navigation d-none d-md-block">
        <div
          class="nav-link"
          routerLinkActive="active"
          [routerLink]="[AppRoutes.promoOfTheWeek]"
        >
          <i class="fas fa-fish"></i>
          {{ "isp.promo.header.link" | translate }}
        </div>

        <div
          class="nav-link"
          routerLinkActive="active"
          [routerLink]="[AppRoutes.order]"
        >
          <i class="fas fa-receipt"></i>
          {{ "isp.button.view_order_history" | translate }}
        </div>
      </div>

      <div class="d-flex justify-content-end menu">
        <cap-user-menu [menus]="menus" [user]="user$ | async"></cap-user-menu>
      </div>
    </div>
  </div>
</nav>
