<cap-info-table
  class="cap-info--bordered article-list-table"
  [class.readonly]="isReadonly"
  [columns]="tableColumns"
  [autoLayout]="true"
  [data]="tableData"
  [pagination]="false"
>
  <ng-template let-index="index" [capTemplate]="TableField.Remark">
    <cap-input
      [(ngModel)]="remarks[index].remark"
      [disabled]="isReadonly"
      (change)="setRemarks()"
    >
    </cap-input>
  </ng-template>

  <ng-template capTemplate="order_article" let-item="item">
    <i
      class="fas fa-exclamation-triangle"
      *ngIf="item?.order_article"
      [pTooltip]="'isp.article.order-article.tooltip' | translate"
    ></i>
  </ng-template>
</cap-info-table>
