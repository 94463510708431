import { FieldType } from '@ngx-formly/core';
import { Component } from '@angular/core';

@Component({
  template: `
    <p-inputNumber
      [step]="to.step"
      [min]="to?.min"
      [max]="to?.max"
      [showButtons]="true"
      buttonLayout="horizontal"
      [formControl]="formControl"
      [formlyAttributes]="field"
      incrementButtonIcon="pi pi-plus"
      decrementButtonIcon="pi pi-minus"
    >
    </p-inputNumber>
  `,
  styleUrls: ['./input-number.formly-type.scss'],
})
export class InputNumberFormlyTypeComponent extends FieldType {}
