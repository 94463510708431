import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appButtonLoading]',
})
export class ButtonLoadingDirective implements AfterViewInit {
  @Input() 
  public set submitting(value: boolean) {
    this.isSubmitted = value;
    this.addLoadingStuff();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get submitting(): boolean {
    return this.isSubmitted;
  }

  @Input() public loadingClass = 'cap-button--loading';
  @Input() public label: string;

  private isSubmitted: boolean;
  private buttonElement: HTMLButtonElement;

  constructor(
    private readonly el: ElementRef,
    private readonly translateService: TranslateService
  ) {}

  public ngAfterViewInit(): void {
    this.buttonElement = (
      this.el.nativeElement.getElementsByClassName('p-button') as HTMLCollection
    ).item(0) as HTMLButtonElement;
  }

  private addLoadingStuff(): void {
    if (this.buttonElement) {
      const method = this.isSubmitted ? 'add' : 'remove';
      const label = this.isSubmitted ? 'component.loading' : this.label;

      this.buttonElement.classList[method](this.loadingClass);

      const span = (
        this.buttonElement.getElementsByClassName(
          'p-button-label'
        ) as HTMLCollection
      ).item(0) as HTMLSpanElement;

      span.innerText = this.translateService.instant(label);
    }
  }
}
