import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AuthService } from '@capturum/auth';
import { AppRoutes } from '@core/enums/routes.enum';
import { User } from '@capturum/complete';
import { NgxPermissionsService } from 'ngx-permissions';
import { TranslateService } from '@ngx-translate/core';
import { first, mapTo, startWith, takeUntil } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { DestroyBase } from '@capturum/shared';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends DestroyBase implements OnInit {
  public menus: (MenuItem & { hidden?: boolean })[];
  public user$: Observable<User>;
  public AppRoutes: typeof AppRoutes = AppRoutes;
  private managePermissions: string[] = [
    'user.manage.tenant',
    'tenant.manage',
    'module.manage.tenant',
    'translation.manage.tenant',
    'role.manage.tenant',
  ];

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly translateService: TranslateService
  ) {
    super();
    this.user$ = this.authService
      .getAuthenticationState()
      .pipe(mapTo(this.authService.getUser()));
  }

  public ngOnInit(): void {
    this.translateService.onLangChange
      .pipe(
        startWith(this.translateService.defaultLang),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        return this.populateMenus();
      });
  }

  private populateMenus(): void {
    from(this.ngxPermissionsService.hasPermission(this.managePermissions))
      .pipe(first())
      .subscribe((canManage) => {
        this.menus = [
          {
            label: this.translateService.instant('link.profile') as string,
            icon: 'fas fa-user-circle',
            routerLink: `/${AppRoutes.admin}/${AppRoutes.user}/${AppRoutes.profile}`,
          },
          {
            label: this.translateService.instant(
              'config.manage.title'
            ) as string,
            icon: 'fas fa-cog',
            routerLink: `/${AppRoutes.admin}/${AppRoutes.config}`,
            hidden: !canManage,
          },
          {
            label: this.translateService.instant('link.logout') as string,
            icon: 'fas fa-sign-in-alt',
            command: () => {
              this.authService.logout().subscribe(() => {
                this.router.navigate(['/', AppRoutes.auth, AppRoutes.login]);
              });
            },
          },
        ];
      });
  }
}
