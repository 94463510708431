<p-dialog
  #dialog
  [(visible)]="visible"
  [styleClass]="styleClass"
  maskStyleClass="dynamic-dialog-mask"
  [style]="style"
  (onHide)="cancel()"
>
  <ng-template pTemplate="header">
    <h2 class="title">{{ title$ | async }}</h2>
  </ng-template>
  <div class="dynamic-dialog-container__content">
    <ng-container *ngIf="childComponentType; else default">
      <ng-template capDynamicDialogContent></ng-template>
    </ng-container>
  </div>

  <ng-template #default>
    <ng-content></ng-content>
  </ng-template>

  <ng-template pTemplate="footer">
    <div
      class="dynamic-dialog-container__footer"
      *ngIf="showButtons; else showDefaultButtons"
    >
      <cap-button
        styleClass="secondary"
        [label]="cancelButtonTranslationKey | translate"
        (click)="cancel()"
      >
      </cap-button>

      <cap-button
        [styleClass]="'primary success submit'"
        [label]="submitButtonTranslationKey | translate"
        (click)="submit()"
        [disabled]="submitButtonDisabled"
      >
      </cap-button>
    </div>
  </ng-template>
</p-dialog>

<ng-template #showDefaultButtons>
  <cap-button
    [styleClass]="'primary'"
    [label]="'button.close' | translate"
    (click)="hide()"
  >
  </cap-button>
</ng-template>
