import { Injectable } from '@angular/core';
import { ActionOptions, ActionProvider } from '@capturum/builders/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { InterpolationPipe, ToastService } from '@capturum/ui/api';
import { EntityAction } from '@core/enums/entity-action.enum';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

interface ExecuteOptions extends ActionOptions {
  type: string;
  endpoint: {
    method: string;
    url: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ListDeleteService implements ActionProvider {
  constructor(
    private readonly router: Router,
    private readonly confirmationService: ConfirmationService,
    private readonly translateService: TranslateService,
    private readonly toastService: ToastService,
    private readonly interpolationPipe: InterpolationPipe,
    private readonly http: HttpClient
  ) {}

  public execute(options: ExecuteOptions, item: any): void {
    this.confirmationService.confirm({
      header: this.translateService.instant('confirm.title'),
      message: this.translateService.instant('list.delete_confirmation'),
      accept: () => {
        if (options?.endpoint) {
          let url = environment.baseUrl;

          url += this.interpolationPipe.transform(options.endpoint.url, item);

          this.http[options?.endpoint?.method?.toLocaleLowerCase()](
            url
          ).subscribe(() => {
            const title = this.translateService.instant('toast.success.title');
            const message = this.translateService.instant(
              `toast.success.${EntityAction.Delete}`,
              { entity: 'item' }
            );

            this.toastService.success(title, message);
          });
        }
      },
    });
  }
}
