<div class="page__top">
  <div class="page__back" *ngIf="backUrl">
    <a [routerLink]="backUrl">
      <i class="fa fa-chevron-left"></i>
      {{ "button.back-to-overview" | translate }}
    </a>
  </div>

  <div class="page__header">
    <div class="row align-items-center no-gutters">
      <div class="col-5 col-sm-3">
        <div class="page__heading">
          <div #headingDiv>
            <ng-content select="[heading]"></ng-content>
          </div>

          <div *ngIf="!headingDiv?.children?.length">
            <ng-container *ngIf="action && entity">
              {{ action | getAction : entity | async }}
            </ng-container>
          </div>
        </div>
      </div>

      <div class="col-7 col-sm-9">
        <div class="page__actions">
          <ng-content select="[actions]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
