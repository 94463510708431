import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { PermissionService } from '@capturum/complete';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(
    private ngxPermissionsService: NgxPermissionsService,
    private permissionService: PermissionService,
    private router: Router
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const permissions: string[] = route.data.permissions.only;

    return of(this.ngxPermissionsService.getPermissions()).pipe(
      switchMap((result) => {
        return !Object.keys(result).length
          ? this.permissionService.loadPermissions()
          : of(result);
      }),
      switchMap(() => {
        return this.ngxPermissionsService.hasPermission(permissions);
      }),
      tap((hasAccess: boolean) => {
        if (!hasAccess) {
          this.router.navigate([route.data.permissions.redirectTo]);
        }
      })
    );
  }
}
