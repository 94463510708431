import { Pipe, PipeTransform } from '@angular/core';
import { EntityAction } from '@core/enums/entity-action.enum';

@Pipe({
  name: 'getBack',
})
export class GetBackPipe implements PipeTransform {
  public transform(action: EntityAction): string[] {
    return action === EntityAction.Create ? ['../'] : ['../../'];
  }
}
