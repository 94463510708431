import { Component, OnInit } from '@angular/core';
import { AuthService } from '@capturum/auth';
import { CompleteConfigService, VersionCheckService } from '@capturum/complete';
import { DestroyBase } from '@capturum/shared';
import { CSSVarNames, ThemeService } from '@capturum/ui/api';
import { environment } from '@environments/environment';
import { Confirmation, ConfirmationService } from 'primeng/api';
import { Observable } from 'rxjs';
import { InfoTableConfigService } from '@capturum/ui/info-table';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [VersionCheckService],
})
export class AppComponent extends DestroyBase implements OnInit {
  public confirmation: Confirmation;
  public isAuthenticated$: Observable<boolean>;

  constructor(
    private readonly versionCheckService: VersionCheckService,
    private readonly themeService: ThemeService,
    private readonly confirmationService: ConfirmationService,
    private readonly authService: AuthService,
    private readonly configService: CompleteConfigService,
    private readonly infoTableConfigService: InfoTableConfigService,
    private readonly translateService: TranslateService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.fetchVersionChanging();
    this.setTheme();
    this.infoTableConfigService.setConfig({
      defaultTexts: {
        noResults: this.translateService.stream('table.no_results'),
      } as any,
    });

    this.confirmationService.requireConfirmation$.subscribe((confirmation) => {
      return (this.confirmation = confirmation);
    });
    this.isAuthenticated$ = this.authService.getAuthenticationState();
  }

  private setTheme(): void {
    this.themeService.setProps({
      [CSSVarNames.Primary]: '#2e3c77',
      [CSSVarNames.Success]: '#4cd964',
      [CSSVarNames.Warning]: '#ff9500',
      [CSSVarNames.Error]: '#ff3b30',
      '--white': '#ffffff',
      '--primary-font': "'Source Sans Pro', sans-serif",
    });
  }

  private fetchVersionChanging(): void {
    this.versionCheckService.initVersionCheck(
      `${environment.url}/version.json`,
      10000
    );

    this.versionCheckService.onUpdateVersion.subscribe(() => {
      window.location.reload();
    });
  }
}
