import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Popup } from '../models/popup.model';
import { ApiHttpService, ApiService } from '@capturum/api';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PopupService extends ApiService<Popup> {
  protected endpoint = 'popup';
  private localstorageKey = 'readPopups';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public getActivePopup(): Observable<Popup> {
    return this.index<Popup>({
      filters: [
        {
          field: 'is_active',
          value: true,
          operator: 'equals',
        },
      ],
    }).pipe(
      map((popupResponse) => {
        const popup = popupResponse.data?.[0];

        if (popup && !this.popupHasBeenRead(popup.id)) {
          return popup;
        }

        return null;
      })
    );
  }

  public setPopupAsRead(popupId: string): void {
    let readItems: string[] =
      JSON.parse(localStorage.getItem(this.localstorageKey)) || [];

    readItems = [...readItems, popupId];

    localStorage.setItem(this.localstorageKey, JSON.stringify(readItems));
  }

  private popupHasBeenRead(id: string): boolean {
    const readItems: string[] =
      JSON.parse(localStorage.getItem(this.localstorageKey)) || [];

    return readItems.includes(id);
  }
}
