<app-header></app-header>

<div id="wrapper">
  <div class="container page-wrapper gray-bg">
    <div class="page-wrapper-row row">
      <div class="col-12">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
