import { Dialog } from 'primeng/dialog';
import { DialogAction } from '@core/enums/dialog-action.enum';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  HostBinding,
  Input,
  Optional,
  Output,
  Renderer2,
  Type,
  ViewChild,
} from '@angular/core';
import { DynamicDialogcontentDirective } from '@capturum/ui/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-dynamic-dialog-container',
  templateUrl: './dynamic-dialog-container.component.html',
  styleUrls: ['./dynamic-dialog-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicDialogContainerComponent implements AfterViewInit {
  @HostBinding('class')
  public class = 'dynamic-dialog-container';

  @Input()
  public showButtons = true;

  @Input()
  public submitButtonTranslationKey = 'button.submit';

  @Input()
  public cancelButtonTranslationKey = 'button.cancel';

  @Input()
  public submitButtonDisabled = false;

  @Input()
  public submitButtonStyleClass = 'success';

  @Input()
  public sendButtonStyleClass = 'primary';

  @Input()
  public styleClass = 'dynamic-dialog';

  @Input()
  public title$: Observable<string>;

  @Input()
  public visible: boolean;

  @Input()
  public style: Record<string, string>;

  @Output()
  public onSubmit = new EventEmitter<any>();

  @Output()
  public onCancel = new EventEmitter<any>();

  @Output()
  public visibleChange = new EventEmitter<boolean>();

  @Output()
  public onHide = new EventEmitter<void>();

  public childComponentType: Type<any>;
  public dismissable = true;
  @ViewChild(DynamicDialogcontentDirective)
  public insertionPoint: DynamicDialogcontentDirective;

  @ViewChild(Dialog, { static: true })
  public dialog: Dialog;

  public componentRef: ComponentRef<any>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    public renderer: Renderer2,
    public config: DynamicDialogConfig,
    @Optional() private dialogRef: DynamicDialogRef
  ) {}

  public ngAfterViewInit(): void {
    if (this.childComponentType) {
      this.loadChildComponent(this.childComponentType);
      this.cd.detectChanges();

      if (this.config && this.config.data) {
        this.title$ = this.config.data.title$;
        this.showButtons = this.config.data.showButtons ?? true;
        this.submitButtonStyleClass =
          this.config.data.submitButtonStyleClass || 'success';
        this.submitButtonTranslationKey =
          this.config.data.submitButtonTranslationKey || 'button.submit';
        this.styleClass = this.config.data.styleClass ?? 'dynamic-dialog';
        this.style = this.config.style ?? null;

        if (this.config.dismissableMask !== undefined) {
          this.dismissable = this.config.dismissableMask;
        }
      }

      this.visible = true;
      this.visibleChange.emit(true);
      this.cd.detectChanges();
    }
  }

  public loadChildComponent(componentType: Type<any>): void {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(componentType);
    const viewContainerRef = this.insertionPoint.viewContainerRef;

    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent(componentFactory);
  }

  public hide($event?: Event): void {
    this.visible = false;
    this.visibleChange.emit(false);
    this.onHide.emit();
    if (this.dialogRef) {
      this.dialogRef.close(DialogAction.Cancel);
    }

    if (this.childComponentType) {
      // Wait for the animation to finish
      setTimeout(() => {
        this.componentRef.destroy();
        this.dialogRef.destroy();
      }, 300);
    }
  }

  public cancel(): void {
    this.visible = false;
    this.visibleChange.emit(false);
    this.onCancel.emit();
    this.dialogRef.close(DialogAction.Cancel);
  }

  public close(): void {
    this.dialogRef.onClose.pipe(
      filter((action) => {
        return action === DialogAction.Close;
      }),
      first()
    );
  }

  public submit(): void {
    this.onSubmit.emit();
    if (this.dialogRef) {
      this.dialogRef.close(DialogAction.Submit);
    }
  }
}
