import { Component, OnInit } from '@angular/core';
import { DestroyBase } from '@capturum/shared';
import { InfoTableConfigService } from '@capturum/ui/info-table';
import { TranslateService } from '@ngx-translate/core';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from '@capturum/auth';
import { BaseDataKeyService } from '@capturum/complete';
import { PopupService } from '../../../../../features/popup/services/popup.service';
import { PopupDialogComponent } from '@shared/components/popup-dialog/popup-dialog.component';
import { CapturumDialogService } from '@capturum/ui/api';
import { DynamicDialogContainerComponent } from '@shared/components/dynamic-dialog-container/dynamic-dialog-container.component';
import { of } from 'rxjs';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Popup } from '../../../../../features/popup/models/popup.model';

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss'],
  providers: [CapturumDialogService],
})
export class BasicLayoutComponent extends DestroyBase implements OnInit {
  constructor(
    private infoTableConfigService: InfoTableConfigService,
    private translateService: TranslateService,
    private authService: AuthService,
    private baseDataKeyService: BaseDataKeyService,
    private popupService: PopupService,
    private dialogService: CapturumDialogService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.setTranslations();
    this.updateInfoTableConfig();
    this.loadBaseKeys();
    this.showActivePopup();
  }

  private updateInfoTableConfig(): void {
    this.translateService.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.infoTableConfigService.setConfig({
          texts: {
            positive: this.translateService.instant('general.yes') as string,
            negative: this.translateService.instant('general.no') as string,
          },
          defaultTexts: {
            filtersLabel: this.translateService.instant(
              'table.filters'
            ) as string,
            itemsSelectedMessage: '{0} items selected',
            resetSortingLabel: this.translateService.instant(
              'table.reset-sorting'
            ) as string,
            descendingLabel: this.translateService.instant(
              'table.descending'
            ) as string,
            ascendingLabel: this.translateService.instant(
              'table.ascending'
            ) as string,
            sortButtonLabel: this.translateService.instant(
              'table.sort-data'
            ) as string,
            resetFilterButtonLabel: 'Clear filters',
            noResults: this.translateService.instant(
              'table.no_results'
            ) as string,
            footerPaginationText: this.translateService.instant(
              'table.paginator.text'
            ) as string,
            filtersEditHeader: this.translateService.instant(
              'table.edit-filters.header'
            ) as string,
            filtersEditCloseBtn: this.translateService.instant(
              'table.edit-filters.close'
            ) as string,
            filtersEditSelectItemsMessage: this.translateService.instant(
              'table.edit-filters.message'
            ) as string,
          },
        });
      });
  }

  private setTranslations(): void {
    this.translateService.addLangs(['nl', 'en', 'de', 'it']);
    this.translateService.setDefaultLang('en');
    this.translateService.use(this.translateService.defaultLang);

    this.authService
      .getAuthenticationState()
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe(() => {
        this.translateService.use(this.authService.getUser().locale.code);
      });
  }

  private loadBaseKeys(): void {
    this.baseDataKeyService.extractBaseDataKeys(this.authService.getUser());
  }

  private showActivePopup(): void {
    let dialogRef: DynamicDialogRef;
    let popup: Popup | null;

    this.popupService
      .getActivePopup()
      .pipe(
        first(),
        switchMap((response: Popup) => {
          popup = response;
          if (response) {
            dialogRef = this.dialogService.open(
              PopupDialogComponent,
              {
                data: {
                  title$: of(response.title),
                  showButtons: false,
                  popup: response,
                },
                header: response.title,
              },
              DynamicDialogContainerComponent
            );

            return dialogRef.onClose;
          }

          return of(null);
        })
      )
      .subscribe(() => {
        if (dialogRef) {
          dialogRef.destroy();
          if (popup) {
            this.popupService.setPopupAsRead(popup.id);
          }
        }
      });
  }
}
