import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-readonly-property',
  templateUrl: './readonly-property.component.html',
  styleUrls: ['./readonly-property.component.scss'],
})
export class ReadonlyPropertyComponent {
  @Input() public label: string;
  @Input() public value: any;
}
