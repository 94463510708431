<ng-container *ngIf="popup">
  <img
    class="popup-img"
    *ngIf="popup.file"
    [src]="popup.file.public_url"
    alt=""
  />

  <div class="popup-description" [innerHTML]="popup.description"></div>
</ng-container>
